<template>
  <div class="add-new-stock">
    <title-bar
      submitBtn
      submitBtnTitle="Create warehouse"
      title="New warehouse"
      @submitPressed="submitPressed"
    />
    <stock-form :stockObject="stock" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import StockForm from './components/StockForm.vue'

export default {
  components: {
    TitleBar,
    StockForm
  },
  data() {
    return {
      action: null,
      stock: {
        name: '',
        telephone: '',
        email: '',
        description: '',
        active: true,
        address: {
          streetAddress: '',
          streetNumber: '',
          city: 'Vienna',
          postalCode: '',
        },
      },
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  }
}
</script>
